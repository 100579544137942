import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SeccionVerde from './shared/SeccionVerde';
import v1 from "../assets/icons/v1.png";
import v2 from "../assets/icons/v2.png";
import v3 from "../assets/icons/v3.png";
import v4 from "../assets/icons/v4.png";
import v5 from "../assets/icons/v5.png";
import v6 from "../assets/icons/v6.png";
import v7 from "../assets/icons/v7.png";
import Valor from './Valor';

import Ahorre from "../assets/penta/ahorre.png";


const NuestrosValores = ({id}) => {
    return (
        <div id={id}>
             <br />
            <SeccionVerde title="Nuestros Valores" />
            <Container>
                <Row className="justify-content-center">
                    <Col md={2} className="three-cols green flex"><Valor icon={v1} text="Transparencia" /></Col>
                    <Col md={2} className="three-cols green flex"><Valor icon={v2} text="Innovación" /></Col>
                    <Col md={2} className="three-cols green flex"><Valor icon={v3} text="Eficiencia" /></Col>
                    <Col md={2} className="three-cols green flex"><Valor icon={v4} text="Integridad" /></Col>
                    <Col md={2} className="three-cols green flex"><Valor icon={v5} text="Sostenibilidad" /></Col>
                    <Col md={2} className="three-cols green flex"><Valor icon={v6} text="Seguridad" /></Col>
                    {/* <Col className="three-cols green flex"><Valor icon={v7} text="Trabajo en Equipo" /></Col> */}
                </Row>
            </Container>
            <br />
            <div>
                <img className="img-fluid" src={Ahorre} alt="Ahorre" />
            </div>
        </div>
    );
};

export default NuestrosValores;