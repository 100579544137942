import React, { useState } from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import './Navigation.css';
import Logo from '../../assets/lgo.png';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)

    return (
        <Navbar className="mainbar" light expand="md" style={{backgroundColor: "white!important"}}>
            <NavbarBrand href="/">
                <img className="img-fluid" width={50} src={Logo} alt="Logo" />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink href="/">INICIO</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/conocenos">CONÓCENOS</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/servicios">SERVICIOS</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/seguridad">SEGURIDAD</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/contacto">CONTACTO</NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink href="/">ENGLISH</NavLink>
                    </NavItem> */}
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default Navigation;