import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Michigan = ({ title = "", leftImage = false, image = null, titleColor="black", txtColor="red", ...props}) => {

    function getText() {
        return (
            <Col xs={12} md={7}>
                <h1 style={{color: titleColor}} >{title}</h1>
                <br />
                <p>{props.children}</p>
            </Col>
        )
    }
    function getImg() {
        return (
            <Col xs={12} md={5}>
                <img className="img-fluid" src={image} alt={title} />
            </Col>
        )
    }

    return (
        <Container>
            <Row>
                {leftImage ? getImg() : getText()}
                {leftImage ? getText() : getImg()}
            </Row>
        </Container>
    );
};

export default Michigan;