import React from 'react';
import { Col } from 'reactstrap';
import "./Tercia.css";

const Tercia = ({ image = null, title = "", wrapImage=true, txtColor="black", vertical=false, ...props }) => {
    return (
        <Col xs={12} md={4} className="three-cols">
            <h3 className="mb-1">{title}</h3>
            <div className={`${wrapImage ? "img-wrapper mb-2" : "mb-2"} ${vertical ? "img-wrapper-vert" : ""} text-center` }>
                <img className="img-fluid" width="90%" src={image} alt={`img-${title}`} />
            </div>
            <div style={{color: txtColor}}>
                {props.children}
            </div>
        </Col>
    );
};

export default Tercia;