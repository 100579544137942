import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import "./ServiciosVerde.css";

import verdeImg1 from "../assets/verde1.png"
import verdeImg2 from "../assets/verde2.png"
import verdeImg3 from "../assets/verde3.png"
import verdeImg4 from "../assets/verde4.png"

const ServiciosVerde = ({id}) => {
    return (
        <div id={id} className="bg-verde">
            <Container>
                <Row className="mb-5">
                    <Col xs={12} className="text-center sb mt-5">
                        <h1 style={{ color: "white" }}>Gas Natural</h1>
                        <h3 style={{ color: "white" }}>más limpio</h3>
                    </Col>
                </Row>
            </Container>
            <div>
                <Row>
                    <Col xs={6} >
                        <Row className="justify-content-center mt-1 mb-2">
                            <Col xs={2}>
                                <img className="img-fluid" src={verdeImg1} alt="Servicios Verdes 1" />
                            </Col>
                            <Col xs={9} style={{ width: "100%", color: "white" }}> <h5>Wyetree</h5>
                                <p>
                                    Es un comerciante y proveedor de gas natural, y desarrollador de tecnología para energía limpia. Contamos con filiales en los mercados
                                    de exportación, donde somos un proveedor autorizado de Gas
                                    Natural (GN) y Gas Natural Licuado (GNL)
                                </p>
                            </Col>
                        </Row>


                        <Row className="justify-content-center mt-1 mb-2">
                            <Col xs={2}>
                                <img className="img-fluid" src={verdeImg2} alt="Servicios Verdes 2" />
                            </Col>
                            <Col xs={9} style={{ width: "100%", color: "white" }}> <h5>Gas Natural</h5>
                                <p>
                                    Alcance importantes ahorros al utilizar gas natural en lugar de
                                    propano o diésel. Genere un entorno más limpio y saludable.
                                </p>
                            </Col>
                        </Row>


                        <Row className="justify-content-center mt-1 mb-2">
                            <Col xs={2}>
                                <img className="img-fluid" src={verdeImg3} alt="Servicios Verdes 3" />
                            </Col>
                            <Col xs={9} style={{ width: "100%", color: "white" }}> <h5>Gas Natural Licuado</h5>
                                <p>
                                    Wyetree es una empresa dedicada a llevar gas natural a las comunidades
                                    rurales y zonas marginadas con el propósito de brindar una alternativa
                                    menos contaminante que otros hidrocarburos como lo es el combustóleo,
                                    diésel, gasolina o gas L.P.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} >
                        <Row className="justify-content-center mt-1 mb-2">
                            <Col xs={2}>
                                <img className="img-fluid" src={verdeImg4} alt="Servicios Verdes 4" />
                            </Col>
                            <Col xs={9} style={{ width: "100%", color: "white" }}> <h5>{`EPC & O&M`}</h5>
                                <p>
                                    Contamos con un equipo altamente especializado y con gran experiencia en diseñar, desarrollar e implementar proyecto de Gas Natural.
                                    Siempre pensando en diseños utilizando alta tecnología y lograr un
                                    proyecto exitoso con beneficios garantizados a corto y largo plazo para
                                    nuestros clientes.
                                </p>
                                <p>
                                    A WYETREE le interesa estar invertido y ser responsable de nuestros
                                    proyectos por lo que ofrecemos ser el operador y dar mantenimiento al
                                    sistema.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ServiciosVerde;