import React from 'react';
import { Container, Row } from 'reactstrap';
import SeccionBlanca from './shared/SeccionBlanca';
import Tercia from './shared/Tercia';
import p1 from "../assets/proyecto1.png";
import p2 from "../assets/proyecto2.png";
import p3 from "../assets/proyecto3.png";
import Beneficios from './shared/Beneficios';

const Proyectos = ({id}) => {
    return (
        <div id={id}>
            <SeccionBlanca title="Proyectos" />

            <Container style={{marginTop:"-30px"}}>
                <Row>
                    <Tercia title="La Herradura" image={p1} wrapImage={false}>
                        <p style={{ fontSize: "1rem", lineHeight: "1.2rem" }}>
                            Nuestro modelo de negocio, centrado
                            en la creación de valor, está comprometido con el desarrollo sostenible de
                            la sociedad, garantizando el suministro
                            de energía competitiva, segura y con
                            el máximo respeto al medio ambiente.
                        </p>
                    </Tercia>

                    <Tercia title="La Rosita" image={p2} wrapImage={false}>
                        <p style={{ fontSize: "1rem", lineHeight: "1.2rem" }}>
                            Nuestro modelo de negocio, centrado
                            en la creación de valor, está comprometido con el desarrollo sostenible de
                            la sociedad, garantizando el suministro
                            de energía competitiva, segura y con
                            el máximo respeto al medio ambiente.
                        </p>
                    </Tercia>
                    <Tercia title="Gonzales Ortega" image={p3} wrapImage={false}>
                        <p style={{ fontSize: "1rem", lineHeight: "1.2rem" }}>
                            Nuestro modelo de negocio, centrado
                            en la creación de valor, está comprometido con el desarrollo sostenible de
                            la sociedad, garantizando el suministro
                            de energía competitiva, segura y con
                            el máximo respeto al medio ambiente.
                        </p>
                    </Tercia>
                </Row>
                {/* <Beneficios /> */}
            </Container>
        </div>
    );
};

export default Proyectos;