import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './SeccionBlanca.css'

const SeccionBlanca = ({title = "Sin texto", sub=""}) => {
    return (
        <Container>
            <Row className="mt-5 mb-5">
                <Col xs={12} className="text-center sb">
                    <h1>{title}</h1>
                    <h3>{sub}</h3>
                </Col>
            </Row>
        </Container>
    );
};

export default SeccionBlanca;