import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import "./Footer.css";
import FooterLogo from "../../assets/footerlogo.png";
import FB from "../../assets/fb.png";
import TW from "../../assets/tw.png";
import MA from "../../assets/icons/ma.png";
import PH from "../../assets/icons/ph.png";
import GM from "../../assets/icons/gm.png";
import IN from "../../assets/icons/in.png";

const Footer = ({ id = "footer" }) => {
    return (
        <div id={id} className="footer pt-5">
            <Container className="mt-2">
                <Row>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}>
                                <h5>CONTACTO</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2} style={{ textAlign: "right" }}>
                                <img src={PH} alt="smedia" className="img-fluid" style={{ width: "30px" }} />
                            </Col>
                            <Col xs={10}>
                                669 226 0500
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={2} style={{ textAlign: "right" }}>
                                <img src={MA} alt="smedia" className="img-fluid" style={{ width: "30px" }} />
                            </Col>
                            <Col xs={10}>
                                hola@wyetree.com
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={2} style={{ textAlign: "right" }}>
                                <img src={GM} alt="smedia" className="img-fluid" style={{ width: "30px" }} />
                            </Col>
                            <Col xs={10}>
                                Ave Doctor Paliza 73 A
                                <br />
                                Col. Centenario, Hermosillo
                                <br />
                                C.P. 83260 Sonora
                                <br />
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} md={4}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <Row>
                            <Col style={{ textAlign: "right" }}>
                                <img src={FB} alt="smedia" className="img-fluid" style={{ width: "40px" }} />
                            </Col>
                            <Col style={{ textAlign: "center" }}>
                                <img src={TW} alt="smedia" className="img-fluid" style={{ width: "40px" }} />
                            </Col>
                            <Col style={{ textAlign: "left" }}>
                                <img src={IN} alt="smedia" className="img-fluid" style={{ width: "40px" }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="logo-wrapper pt-3">
                            <img className="img-fluid" src={FooterLogo} alt="footerlogo" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <br />
                        <br />
                        <p style={{ width: "100%", textAlign: "center" }}>
                            ©2014 -2021 Wyetree Todos los derechos reservados
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;