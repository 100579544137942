import React from 'react';
import ModeloDeNegocio from '../components/ModeloDeNegocio';
import NuestrosServicios from '../components/NuestrosServicios';
import NuestrosValores from '../components/NuestrosValores';
import Proyectos from '../components/Proyectos';
import Footer from '../components/shared/Footer';
import Navigation from '../components/shared/Navigation';
import Slider from '../components/Slider';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import w1 from '../assets/W1.mp4';

const Inicio = () => {

    function scrollHandler(e, element) {
        let hero = document.getElementById(element);
        e.preventDefault();  // Stop Page Reloading
        hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return (
        <React.Fragment>
            <Navigation />
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "video")}>
                <Slider id="slider" />
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "modelo")} upHandler={e => scrollHandler(e, "slider")}>
                <div id="video" style={{ width: "100%", maxWidth: "100vw", textAlign: "center" }}>
                    <video controls style={{marginTop: "10vh"}}>
                        <source src={w1} type="video/mp4" />
                    </video>
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "valores")} upHandler={e => scrollHandler(e, "video")}>
                <ModeloDeNegocio id="modelo" />
            </ReactScrollWheelHandler>
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "servicios")} upHandler={e => scrollHandler(e, "modelo")}>
                <NuestrosValores id="valores" />
            </ReactScrollWheelHandler>
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "proyectos")} upHandler={e => scrollHandler(e, "valores")}>
                <NuestrosServicios id="servicios" />
            </ReactScrollWheelHandler>
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "footer")} upHandler={e => scrollHandler(e, "servicios")}>
                <Proyectos id="proyectos" />
            </ReactScrollWheelHandler>
            <ReactScrollWheelHandler upHandler={e => scrollHandler(e, "proyectos")}>
                <Footer />
            </ReactScrollWheelHandler>
        </React.Fragment>
    );
};

export default Inicio;