import React from 'react';
import { Row } from 'reactstrap';
import prop1 from "../../assets/prop1.png";
import prop2 from "../../assets/prop2.png";
import prop3 from "../../assets/prop3.png";
import Tercia from './Tercia';

const Beneficios = () => {
    return (
        <div>
            <Row>
                <Tercia title="Engergía más limpia" image={prop1} wrapImage={true} txtColor="black" vertical={true}>
                    <b>Reducir costos de energía y emisiones al medio ambiente</b>
                    <br />
                    <br />
                    <ul className="tercia-list">
                        <li>
                            Gas natural más limpio y de bajo precio
                        </li>
                        <li>
                            Producción local de GLN más cerca de nuestros clientes.
                        </li>
                        <li>
                            Acceso a los gasoductos de gas natural.
                        </li>
                    </ul>
                </Tercia>
                <Tercia title="GLN más limpio" image={prop2} wrapImage={true} txtColor="black" vertical={true}>
                    <b>Reemplace el diésel caro y contaminante por GLN</b>
                    <br />
                    <br />
                    <ul className="tercia-list">
                        <li>
                            Ahorro sustancial de costes y reducciones de emisiones
                        </li>
                        <li>
                            30% menos de CO2
                        </li>
                        <li>
                            Hasta 95% menos de NOx
                        </li>
                        <li>
                            75% menos de Monóxido de Carbono
                        </li>
                        <li>
                            Mismo Rendimiento
                        </li>
                        <li>
                            Evita robo de combustible
                        </li>
                        <li>
                            Reducción en Mantenimiento
                        </li>
                    </ul>
                </Tercia>
                <Tercia title="Industria minera" image={prop3} wrapImage={true} txtColor="black" vertical={true}>
                    <b>Genere ahorros utilizando el sistema dual GLN/Diesel en sus flotillas de operación</b>
                    <br />
                    <br />
                    <ul>
                        <li>
                            Ahorros Substanciales
                        </li>
                        <li>
                            Mismo Rendimiento
                        </li>
                        <li>
                            Evita robo de combustible
                        </li>
                        <li>
                            Reducción en Mantenimiento
                        </li>
                    </ul>
                </Tercia>
            </Row>
        </div>
    );
};

export default Beneficios;