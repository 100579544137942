import React from 'react';

const Valor = ({icon=null, text="Text"}) => {
    return (
        <div>
            <div className="img-wrapper">
                <img src={icon} className="img-fluid" alt="valor" />
            </div>
            <p style={{ width: "100%", textAlign: "center" }}>{text}</p>
        </div>
    );
};

export default Valor;