import React from 'react';
import Slider from '../components/Slider';
import Footer from '../components/shared/Footer';
import Navigation from '../components/shared/Navigation';
import { Col, Container, Row } from 'reactstrap';

import img1 from "../assets/servicio1.png"
import img2 from "../assets/servicio2.png"
import img3 from "../assets/servicio3.png"
import img4 from "../assets/servicio4.png"
import img5 from "../assets/servicio5.png"
import HistoriaImg from '../assets/nuestrahistoria.png';
import Beneficios from '../components/shared/Beneficios';

import GrandesAhorros from "../assets/grandesAhorros.png";
import Michigan from '../components/shared/Michigan';
import ConocenosImg from "../assets/conocenosEtica.png";
import PoliticasImg from "../assets/politicas.png";
import CoviImg from "../assets/covi.jpg";
import TuboImg from "../assets/tubo.jpg";

import ReactScrollWheelHandler from "react-scroll-wheel-handler";

const Conocenos = () => {
    function scrollHandler(e, element) {
        let hero = document.getElementById(element);
        e.preventDefault();  // Stop Page Reloading
        hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    return (
        <React.Fragment>
            <Navigation />
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "valores")}>
                <Slider id="slider" />
            </ReactScrollWheelHandler>
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "etica")} upHandler={e => scrollHandler(e, "slider")}>
                <Container id="valores">
                    <Row className="justify-content-center">
                        <Col xs={12} md={2}>
                            <div className=" bg bg1">
                                <a href="servicios#gas_natural">
                                    <img className="img-fluid" src={img1} alt="Servicios1" />
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                            <div className=" bg bg2">
                                <a href="servicios#gas_natural_licuado">
                                    <img className="img-fluid" src={img2} alt="Servicios1" />
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                            <div className=" bg bg3">
                                <a href="servicios#gas_natural_renovable">
                                    <img className="img-fluid" src={img3} alt="Servicios1" />
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                            <div className=" bg bg4">
                                <a href="servicios#epc">
                                    <img className="img-fluid" src={img4} alt="Servicios1" />
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                            <div className=" bg bg5">
                                <a href="servicios#comercializacion">
                                    <img className="img-fluid" src={img5} alt="Servicios1" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Container className="mt-5">
                        <Row>
                            <Col xs={4}>
                                <Row>
                                    <h1>Nuestra historia</h1>
                                </Row>
                                <Row className="mt-2">
                                    <p>
                                        Wyetree fue fundada en 2014 y es un comerciante internacional y proveedor de gas natural y un
                                        desarrollador de tecnología de energía limpia. Contamos con filiales en varios mercados de exportación donde somos un proveedor con licencia de gas natural y GNL.
                                    </p>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <div className="mr-auto ml-auto text-center">
                                    <img className="img-fluid" src={HistoriaImg} alt="Nuestra historia" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </ReactScrollWheelHandler>


            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "politica")} upHandler={e => scrollHandler(e, "valores")}>
                <div id="etica">
                    <Container>
                        <Michigan title="Ética de Trabajo" image={ConocenosImg}>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </Michigan>
                    </Container>
                    <br />
                    <img className="img-fluid" src={GrandesAhorros} alt="Grandes ahorros" />
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "secretaria")} upHandler={e => scrollHandler(e, "etica")}>
                <div id="politica">
                    <Container>
                        <Michigan title="Políticas y códigos de conducta" image={PoliticasImg}>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </Michigan>
                    </Container>
                    <br />
                    <img className="img-fluid" src={GrandesAhorros} alt="Grandes ahorros" />
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "beneficios")} upHandler={e => scrollHandler(e, "politica")}>
                <div id="secretaria">
                    <Michigan title="Secretaría General de Conducta" image={CoviImg}>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                    </Michigan>
                    <br />
                    <Michigan title="Visión estratégica" image={TuboImg}>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                    </Michigan>
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "footer")} upHandler={e => scrollHandler(e, "secretaria")}>
                <Container id={"beneficios"}>
                <Beneficios />
                </Container>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler upHandler={e => scrollHandler(e, "beneficios")}>
            <Footer />
            </ReactScrollWheelHandler>
        </React.Fragment>
    );
};

export default Conocenos;