import { Col, Container, Row } from 'reactstrap';
import './SeccionVerde.css';

const SeccionVerde = ({title = "Sin texto", center = true}) => {
    return (
        <Container>
            <Row className="mt-2 mb-5">
                <Col xs={12} className={`${center ? "text-center" : "text-left"} sv self-align-middle`}>
                    <h1>{title}</h1>
                </Col>
            </Row>
        </Container>
    );
};

export default SeccionVerde;