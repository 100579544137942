import React from 'react';
import Footer from '../components/shared/Footer';
import Navigation from '../components/shared/Navigation';
import Slider from '../components/Slider';
import ServiciosVerde from '../components/ServiciosVerde';
import Servicio from '../components/Servicio';
import Beneficios from '../components/shared/Beneficios';

import s1 from "../assets/servicios/servicios1.jpg";
import s2 from "../assets/servicios/servicios2.jpg";
import s3 from "../assets/servicios/servicios3.jpg";
import s4 from "../assets/servicios/servicios4.jpg";
import s5 from "../assets/servicios/servicios5.jpg";

import ico1 from "../assets/servicios/ico1.png"
import ico2 from "../assets/servicios/ico2.png"
import ico3 from "../assets/servicios/ico3.png"
import ico4 from "../assets/servicios/ico4.png"
import ico5 from "../assets/servicios/ico5.png"

import { Container } from 'reactstrap';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

const Servicios = () => {

    function scrollHandler(e, element) {
        let hero = document.getElementById(element);
        e.preventDefault();  // Stop Page Reloading
        hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return (
        <React.Fragment>
            <Navigation />
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "verde")}>
                <Slider id="slider" />
            </ReactScrollWheelHandler>
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "gas_natural")} upHandler={e => scrollHandler(e, "slider")}>
                <ServiciosVerde id="verde" />
            </ReactScrollWheelHandler>
            <br />
            <div>
                <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "gas_natural_licuado")} upHandler={e => scrollHandler(e, "verde")}>
                    <Servicio id="gas_natural" image={s1} title="Gas Natural" ico={ico1} >
                        <p>
                            Wyetree es un comerciante internacional y proveedor de gas natural y un desarrollador
                            de tecnología de energía limpia.
                        </p>
                        <p>
                            Contamos con filiales en varios mercados de exportación donde somos un proveedor
                            con licencia de gas natural y GNL.
                        </p>
                        <p>
                            <b>
                                <i>
                                    “Nuestros clientes pueden lograr ahorros sustanciales de costos al alimentar sus
                                    instalaciones industriales con gas natural.”
                                </i>
                            </b>
                        </p>
                        <p>
                            Tenemos capacidad de tuberías desde varias ubicaciones, incluyendo gas natural de
                            bajo precio de Waha. Podemos entregar GNL en cualquier lugar si no se encuentra en un
                            área con gasoducto existente. También podemos ayudarle a conectarse si se encuentra
                            cerca de una tubería de transmisión existente.
                        </p>
                        <p>
                            Remplazar el uso de hidrocarburos tradicionales con gas natural conlleva grandes ben
                            eficios a los usuarios, que van desde lo económico y sobre todo reducción de impacto al
                            medio ambiente por ser un combustible más limpio. Nuestros clientes logran alcanzar
                            reducciones substanciales en el costo al alimentar sus instalaciones industriales con gas
                            natural.
                        </p>
                    </Servicio>
                </ReactScrollWheelHandler>

                <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "gas_natural_renovable")} upHandler={e => scrollHandler(e, "gas_natural")}>
                    <Servicio id="gas_natural_licuado" image={s2} title="Gas Natural Licuado" ico={ico2}>
                        <p>
                            <i>"El GNL, al estar libre de impurezas reduce los costos de mantenimiento, extiende la
                                vida y funcionamiento del equipo.”</i>
                        </p>
                        <p>
                            Wyetree es una empresa dedicada a llevar gas natural a las comunidades rurales y zonas
                            marginadas con el propósito de brindar una alternativa menos contaminante que otros
                            hidrocarburos como lo es el combustóleo, diésel, gasolina o gas l.p. Remplazar el uso de
                            hidrocarburos tradicionales con gas natural trae grandes beneficios a los usuarios, que
                            van desde lo económico y sobre todo reducción de impacto al medio ambiente por ser
                            un combustible más limpio, nuestros clientes logran alcanzar reducciones substanciales
                            en el costo de generación de energía y el costo operacional de equipo o maquinaria
                            pesada.
                        </p>
                        <p>
                            El gas natural antes de pasar al proceso de licuefacción es sometido a un tratamiento, en
                            el cual el CO2, sulfuro de hidrógeno y otras impurezas son separadas, resultando Gas
                            Natural Licuado con alta concentración de metano (GNL), y más limpio que el del Gaso
                            ducto. El GNL, al estar libre de impurezas reduce los costos de mantenimiento, extiende
                            la vida y funcionamiento del equipo.
                        </p>
                        <p>
                            Estamos construyendo plantas de GNL en varios lugares más cercanos a nuestros clien
                            tes, lo que reducirá los costos de transporte y nos permitirá ofrecer un menor precio de
                            GNL.
                        </p>
                    </Servicio>
                </ReactScrollWheelHandler>
                <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "epc")} upHandler={e => scrollHandler(e, "gas_natural_licuado")}>
                    <Servicio id="gas_natural_renovable" image={s3} title="Gas Natural Renovable" ico={ico3} >
                        <p>
                            Nuestro compromiso con el medio ambiente nos ha llevado a entender, desarrollar e
                            invertir en infraestructura que permitan el acceso y uso de Gas Natural Renovable
                            como fuente de energía estable y sustentable.
                        </p>
                        <p>
                            <b>
                                <i>
                                    "Nuestras soluciones de Gas Natural Renovable (GNR) tienen como objetivo mezclar
                                    el GNL con el gas natural fósil y aprovechar la infraestructura de gas natural existente, permitiendo a los grandes consumidores de combustible y calor utilizar indirectamente más energía renovable"
                                </i>
                            </b>
                        </p>
                    </Servicio>
                </ReactScrollWheelHandler>
                <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "comercializacion")} upHandler={e => scrollHandler(e, "gas_natural_renovable")}>
                    <Servicio id="epc" image={s4} title="EPC" ico={ico4}>
                        <p>
                            Contamos con un equipo altamente especializado y con gran experiencia en diseñar,
                            desarrollar e implementar proyecto de Gas Natural. Siempre pensando en diseños utilizando alta tecnología y lograr un proyecto exitoso con beneficios garantizados a corto
                            y largo plazo para nuestros clientes.
                        </p>
                        <p>
                            A WYETREE le interesa estar invertido y ser responsable de nuestros proyectos por lo
                            que ofrecemos ser el operador y dar mantenimiento al sistema.
                        </p>
                        <p>
                            <b>
                                <i>
                                    Estaciones de producción de GNL ubicadas más cerca de los sitios mineros
                                    Estamos construyendo plantas de GNL en varios lugares más cercanos a nuestros
                                    clientes, lo que reducirá los costos de transporte y nos permitirá ofrecer un menor
                                    precio de GNL
                                </i>
                            </b>
                        </p>
                    </Servicio>
                </ReactScrollWheelHandler>
                <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "beneficios")} upHandler={e => scrollHandler(e, "epc")}>

                    <Servicio id="comercializacion" image={s5} title="Comercializacion" ico={ico5} >
                        <p>
                            WYETREE es parte de la cadena de suministro de Gas Natural por ducto que viene de las
                            cuencas del Sur de Texas y se interna a México a través de los múltiples gasoductos que
                            se encuentran en operación. Para cada cliente Garantizamos capacidad y molécula
                            tanto en los ductos de transporte dentro de los Estados Unidos como en los ductos de
                            transporte en territorio Mexicano hasta el punto de entrega final.
                        </p>
                        <p>
                            <b>
                                <i>
                                    Desarrollamos y proporcionamos soluciones para energías más limpias que son
                                    rentables y tienen un alto impacto en la reducción de emisiones.
                                </i>
                            </b></p>
                    </Servicio>
                </ReactScrollWheelHandler>
            </div>
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "footer")} upHandler={e => scrollHandler(e, "comercializacion")}>
                <Container id="beneficios"  >
                    <Beneficios />
                </Container>
            </ReactScrollWheelHandler>
            <ReactScrollWheelHandler upHandler={e => scrollHandler(e, "beneficios")}>
                <Footer />
            </ReactScrollWheelHandler>
        </React.Fragment>
    );
};

export default Servicios;