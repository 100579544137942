import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Footer from '../components/shared/Footer';
import Beneficios from '../components/shared/Beneficios';
import Navigation from '../components/shared/Navigation';
import Slider from '../components/Slider';
import "./Seguridad.css"
import RuedaImg from "../assets/rueda.png";
import ObjetivosImg from "../assets/objetivos.png";
import Sec1 from "../assets/seg1.png";
import Sec2 from "../assets/seg2.png";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

const Seguridad = () => {
    function scrollHandler(e, element) {
        let hero = document.getElementById(element);
        e.preventDefault();  // Stop Page Reloading
        hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return (
        <React.Fragment>
            <Navigation />
            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "seguridad")}>
                <Slider id="slider" />
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "politica")} upHandler={e => scrollHandler(e, "slider")} >
                <div id="seguridad" className="seguridad-img pt-5">
                    <Container className="mt-5" >
                        <Row style={{ paddingTop: "66vh" }}>
                            <Col className="text-center">
                                <h1 style={{ color: "black", fontWeight: "700" }}>
                                    Nada es más importante que la seguridad, la salud y el bienestar de las personas.
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "verdin")} upHandler={e => scrollHandler(e, "seguridad")} >
                <div id="politica" className="gray">
                    <Container className="pt-5 pb-1">
                        <Row className="mt-1">
                            <Col xs={12}>
                                <h1>Nuestra Política de Medio Ambiente</h1>
                                <h5>Política y Plan de Medioambiente</h5>
                                <p >
                                    Nos comprometemos a ser un actor clave en la transición energética hacia un modelo de economía circular, bajo en
                                    carbono y digital, haciendo un trabajo en nuestros propios 4 ejes estratégicos.
                                </p>
                                <p >
                                    El capital natural y la diversidad biológica se han convertido en pilares estratégicos en nuestra administración ambiental
                                    que se fundamenta en mejorar nuestro efecto sobre los ecosistemas por medio de el decrecimiento de las emisiones, del
                                    consumo de recursos y de la producción de residuos; y en desarrollar ocupaciones directas sobre la diversidad biológica.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="pt-5 pb-5">
                        <Row className="mt-5">
                            <Col xs={12}>
                                <h1>Impulsamos la mejora continua en las condiciones de trabajo
                                    y en la gestión de la seguridad, la salud y el bienestar</h1>
                                <p >
                                    Nuestra actuación va más allá del cumplimiento de las obligaciones legales y otros requisitos, impulsando la optimización
                                    continua en las condiciones de trabajo y en la administración de la estabilidad, la salud y la paz, involucrando no únicamente
                                    a los expertos de la compañía, sino además a los proveedores, organizaciones colaboradoras, consumidores y otros equipos
                                    de interés, con el objeto de eludir y prevenir los accidentes y los perjuicios a la salud, proporcionando un ambiente seguro y
                                    sana así como favoreciendo su salud y confort.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "triple")} upHandler={e => scrollHandler(e, "politica")} >
                <div id="verdin" className="verdin">
                    <Container className="pt-5 pb-5">
                        <Row className="mt-5">
                            <Col xs={5}></Col>
                            <Col xs={7} className="pl-5">
                                <h1>Nuestros compromisos con la sociedad</h1>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={5} className="pr-5">
                                <br />
                                <img src={RuedaImg} className="img-fluid" alt="rueda" />
                            </Col>
                            <Col xs={7} className="pl-5">
                                <p>Excelencia de servicio</p>
                                <p>Comprometidos con los resultados</p>
                                <p>Gestión responsable del medio ambiente</p>
                                <p>Interés por las personas</p>
                                <p>Seguridad y salud</p>
                                <p>Cadena de suministro responsable</p>
                                <p>Compromiso social</p>
                                <p>Integridad y transparencia</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "relacion")} upHandler={e => scrollHandler(e, "verdin")} >
                <Container id="triple" className="mt-5 pt-5">
                    <Row>
                        <Col className="text-center mt-4">
                            <h1>Triple misión, mismo objetivo</h1>
                            <p className="text-center">
                                Consientes de el valor que poseen nuestras propias actuaciones sobre los individuos y el ámbito
                                donde desarrollamos nuestra actividad, establecemos una secuencia de compromisos que
                                garanticen la paz -actual y futuro- de los individuos y los ámbitos con los que estamos en contacto.
                            </p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center pt-5">
                        <Col xs={10} md={8}>
                            <img className="img-fluid" src={ObjetivosImg} alt="Objetivos" />
                        </Col>
                    </Row>
                </Container>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "principios")} upHandler={e => scrollHandler(e, "triple")} >
                <div id="relacion" className="gray mt-5 pt-5 pb-5">
                    <Container className="mt-5">
                        <br />
                        <br />
                        <br />
                        <Row>
                            <Col xs={12} md={5}>
                                <img className="img-fluid" src={Sec1} alt="Seguridad 1" />
                            </Col>
                            <Col xs={12} md={7}>
                                <h1>Relación con las comunidades</h1>
                                <br />
                                <br />
                                <p >
                                    Dentro de nuestra Política de Derechos Humanos, hemos
                                    adquirido un firme compromiso con el respeto a las sociedades locales
                                </p>
                                <p >
                                    Para la consecución de este compromiso, son puntos
                                    clave la evaluación del efecto social que nuestras propias
                                    ocupaciones logren tener sobre las sociedades dañadas y
                                    la contribución a la optimización de las condiciones de
                                    vida de estas.
                                </p>
                                <p >
                                    En estas evaluaciones se miden varios impactos, entre
                                    ellos, el efecto sobre los derechos humanos.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "beneficios")} upHandler={e => scrollHandler(e, "relacion")} >
                <div id="principios" className="gray pt-5 pb-5">
                    <Container className="mt-5">
                        <br />
                        <br />
                        <Row>
                            <Col xs={12} md={4}>
                                <img className="img-fluid" src={Sec2} alt="Seguridad 2" />
                            </Col>
                            <Col xs={12} md={8}>
                                <h1>Principios en nuestra relación con las comunidades</h1>
                                <br />
                                <br />
                                <p >
                                    Examinar los impactos y potenciales peligros del medio
                                    ambiente y sociales que nuestra actividad logre crear en
                                    las sociedades, por medio de la metodología de evaluación del efecto social diseñada para tal fin.
                                </p>
                                <p >
                                    Promover el desarrollo sustentable de la sociedad incorporando, en los estudios de evaluación del
                                    efecto, las oportunidades identificadas en el diálogo con las sociedades.
                                </p>
                                <p >
                                    Entablar sistemática de comunicación y interacción con
                                    las sociedades que garantice que reciben información del
                                    plan clara, actualizada y eficaz
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ReactScrollWheelHandler>

            <ReactScrollWheelHandler downHandler={e => scrollHandler(e, "footer")} upHandler={e => scrollHandler(e, "principios")} >
                <Container id="beneficios">
                    <Beneficios />
                </Container>
            </ReactScrollWheelHandler>


            <ReactScrollWheelHandler upHandler={e => scrollHandler(e, "beneficios")} >
                <Footer />
            </ReactScrollWheelHandler>
        </React.Fragment>
    );
};

export default Seguridad;