import React, { useEffect, useState } from 'react';
import './Slider.css';

import Mini1 from "../assets/slider/mini1.png"
import Mini2 from "../assets/slider/mini2.png"
import Mini3 from "../assets/slider/mini3.png"
import Mini4 from "../assets/slider/mini4.png"

function Slider({id}) {
    const [actual, setActual] = useState(1);

    useEffect(() => {
        console.log(actual);
        setTimeout(() => {
            setActual(actual > 1 ? actual - 1 : 4)
            // setActual(actual < 4 ? actual + 1 : 1)
            changeOrder();
        }, 4000);
    }, [actual])

    const getTitle = (actual) => {
        switch (actual) {
            case 1:
                return (<h1 className="animation">EL TEXTO TITULO 1</h1>)
                break;
            case 2:
                return (<h1 className="animation">EL TEXTO TITULO 2</h1>)
                break;
            case 3:
                return (<h1 className="animation">EL TEXTO TITULO 3</h1>)
                break;
            case 4:
                return (<h1 className="animation">EL TEXTO TITULO 4</h1>)
                break;

            default:
                break;
        }
    }

    const getText = (actual) => {
        switch (actual) {
            case 1:
                return `1Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
                break;
            case 2:
                return `2Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
                break;
            case 3:
                return `3Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
                break;
            case 4:
                return `4Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
                break;

            default:
                break;
        }
    }

    function changeOrder() {
        const allSlides = document.querySelectorAll(".single-slide");
        const previous = "1";
        const current = "2";
        const next = "3";
        const next2 = "4";
      
        for (const slide of allSlides) {
          const order = slide.getAttribute("data-order");
      
          switch (order) {
            case current:
              slide.setAttribute("data-order", next);
              break;
            case next:
              slide.setAttribute("data-order", next2);
              break;
            case previous:
              slide.setAttribute("data-order", current);
              break;
            case next2:
              slide.setAttribute("data-order", previous);
              break;
            // case current:
            //   slide.setAttribute("data-order", previous);
            //   break;
            // case next:
            //   slide.setAttribute("data-order", current);
            //   break;
            // case previous:
            //   slide.setAttribute("data-order", next2);
            //   break;
            // case next2:
            //   slide.setAttribute("data-order", next);
            //   break;
          }
        }
      }

    const getMinis = () => {
        return (
            <div class="all-slides">
                <div class="single-slide" data-order="1">
                    <img className="img-fluid" src={Mini1} alt="mini1" />
                </div>
                <div class="single-slide" data-order="2">
                    <img className="img-fluid" src={Mini2} alt="mini2" />
                </div>
                <div class="single-slide" data-order="3">
                    <img className="img-fluid" src={Mini3} alt="mini3" />
                </div>
                <div class="single-slide" data-order="4">
                    <img className="img-fluid" src={Mini4} alt="mini4" />
                </div>
            </div>
        )
    }

    return (
        <div id={id}>
            <div className={`slide${actual} animation`}>
                <div className="slider-text">
                    {getTitle(actual)}
                    <div className="text-wrapper">
                        <p>
                            {getText(actual)}
                        </p>
                    </div>
                </div>
                {getMinis(actual)}
            </div>
        </div>
    );
}

export default Slider;