import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Footer from '../components/shared/Footer';
import Navigation from '../components/shared/Navigation';

const Contacto = () => {
    return (
        <React.Fragment>
            <Navigation />
            <div className="verdolaga pt-5 pb-5">
                <Container className="mt-5 mb-5">
                    <Row>
                        <Col xs={12} md={6}>
                            <h3>Nuestras ubicaciones</h3>
                            <br />
                            <p>
                                <b>Londres (Headquarter)</b> <br />
                                DEVONSHIRE HOUSE MAYFAIR PLACE, LEVEL 1, LONDON, W1J 8AJ,<br />
                                ENGLAND
                            </p>
                            <p>
                                Teléfono +44 20 7129 7090<br />
                                Fax +1 (212) 547-9801<br />
                                Correo electrónico hello@wyetree.com
                            </p>
                            <p>
                                <b>México</b><br />
                                AVE DOCTOR PALIZA 73 A, COL. CENTENARIO, HERMOSILLO, C.P.<br />
                                83260 SONORA
                            </p>
                            <p>
                                Teléfono +52 662 110 7935<br />
                                Fax +52 669 226 0540<br />
                                Correo electrónico hola@wyetree.com
                            </p>
                            <p>
                                <b>New York</b><br />
                                1345 AVENUE OF THE AMERICAS, 33RD FLOOR<br />
                                NEW YORK, N.Y. 10105
                            </p>
                            <p>
                                Teléfono +1 (212) 547-9800<br />
                                Fax +1 (212) 547-9801<br />
                                Correo electrónico hello@wyetree.com
                            </p>
                        </Col>
                        <Col xs={12} md={6}>
                            <div class="bg_map map-responsive mt-0 mb-0">
                                <a href="https://goo.gl/maps/rdjEXfuEZRbtUnzbA" target="_blank" rel="noreferrer">
                                    <iframe title="mapa"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3487.063598695998!2d-110.96656708558173!3d29.074265570702853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce84453905f4bb%3A0xd5d12b41ec8b11ca!2sAvenida%20Doctor%20Paliza%2073%2C%20El%20Centenario%2C%2083260%20Hermosillo%2C%20Son.!5e0!3m2!1ses!2smx!4v1628987022775!5m2!1ses!2smx" width="600" height="750" style={{border: 0}} allowfullscreen="" loading="lazy"></iframe>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default Contacto;