import React from 'react';
import { Col, Row } from 'reactstrap';
import "./Servicio.css";
import SeccionVerde from '../components/shared/SeccionVerde';

const Servicio = ({ id = "", image = null, title = "Title", ico = "null", ...props }) => {
    return (
        <div id={id} className="servicio-wrapper pt-5">
            <Row className="mt-5">
                <Col xs={6} className="children-wrapper">
                    <Row>
                        <Col xs={2}>
                            <img className="img-fluid" src={ico} alt={title} />
                        </Col>
                        <Col>
                            <SeccionVerde title={title} center={false} />
                        </Col>
                    </Row>
                    {props.children}
                </Col>
                <Col xs={6}>
                    <div>
                        <img className="img-fluid mb-3 img-title" src={image} alt={title} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Servicio;