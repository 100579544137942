import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SeccionBlanca from './shared/SeccionBlanca';
// import './ModeloDeNegocio.css';
import Vision from '../assets/icons/vision.png'
import Mision from '../assets/icons/mision.png'
import Objetivos from '../assets/icons/objetivos.png'
import Tercia from './shared/Tercia';

const ModeloDeNegocio = ({id}) => {
    return (
        <div id={id}>
            <SeccionBlanca title="Un modelo de negocio" sub="Innovador, integrado y sostenible" />

            <Container>
                <Row>
                    <Col xs={12}>
                        <p>
                            Nuestro modelo de negocio, centrado en la creación de valor, está comprometido con el desarrollo
                            sostenible de la sociedad, garantizando el suministro de energía competitiva, segura y con el
                            máximo respeto al medio ambiente.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <p>
                            Nuestra visión de futuro, sin olvidar nuestras raíces y nuestros más de 175 años de historia, tiene como
                            objeto transformar el actual modelo de negocio y establecer las bases para continuar creando valor a
                            través de la transición energética, apostando por las energías renovables, desarrollando el gas renovable
                            (hidrógeno y biometano) gracias a la posición de liderazgo en el mercado del gas natural convencional, e
                            impulsando la eficiencia energética y la economía circular.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Tercia title="Misión" image={Mision}>
                        <p style={{fontSize:"1rem", lineHeight:"1.2rem"}}>
                            Desarrollar y gestionar infraestructuras de gas en un contexto global, de
                            manera segura, eficiente y sostenible;
                            cumpliendo responsablemente con la
                            regulación vigente y contribuyendo a
                            la garantía de suministro.
                        </p>
                    </Tercia>
                    <Tercia title="Visión" image={Vision}>
                        <p style={{fontSize:"1rem", lineHeight:"1.2rem"}}>
                            Ser referentes a nivel nacional e internacional en el desarrollo y gestión de
                            infraestructuras de gas, impulsando su
                            uso a través de la presentación de servicios innovadores que contribuyan al
                            desarrollo sostenible
                        </p>
                    </Tercia>
                    <Tercia title="Objetivos" image={Objetivos}>
                        <p style={{fontSize:"1rem", lineHeight:"1.2rem"}}>
                            Construcción de nuevas obras.
                            Gasoducto, Plantas de almacenaje y
                            regulación. Tendido de las redes de
                            distribución. Estas, serán diseñadas
                            para Gas Natural.
                        </p>
                    </Tercia>
                </Row>
            </Container>
        </div>
    );
};

export default ModeloDeNegocio;