import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom"; 
import Inicio from './pages/Inicio';
import Conocenos from './pages/Conocenos';
import Servicios from './pages/Servicios';
import Seguridad from './pages/Seguridad';
import Contacto from './pages/Contacto';

function App() {
  return (
    <Router>
        <Route exact path="/">
          <Inicio />
        </Route>
        <Route exact path="/conocenos">
          <Conocenos />
        </Route>
        <Route exact path="/servicios">
          <Servicios />
        </Route>
        <Route exact path="/seguridad">
          <Seguridad />
        </Route>
        <Route exact path="/contacto">
          <Contacto />
        </Route>
    </Router>
  );
}

export default App;
