import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import img1 from "../assets/servicio1.png"
import img2 from "../assets/servicio2.png"
import img3 from "../assets/servicio3.png"
import img4 from "../assets/servicio4.png"
import img5 from "../assets/servicio5.png"
import "./NuestrosServicios.css";
import SeccionBlanca from './shared/SeccionBlanca';

import Inicio1 from "../assets/inicio1.png"
import Inicio2 from "../assets/inicio2.png"
import Inicio3 from "../assets/inicio3.png"
import Tercia from './shared/Tercia';

const NuestrosServicios = ({id}) => {
    return (
        <div id={id}>
            <Container>
                <Row className="mt-5 pl-5 pr-5">
                    <Tercia title="¿Quiénes somos?" image={Inicio1} wrapImage={false}>
                        <p style={{fontSize:"0.9rem", lineHeight:"1.1rem"}}>
                            Nuestro modelo de negocio, centrado
                            en la creación de valor, está comprometido con el desarrollo sostenible de
                            la sociedad, garantizando el suministro
                            de energía competitiva, segura y con
                            el máximo respeto al medio ambiente.
                        </p>
                    </Tercia>

                    <Tercia title="¿Quiénes somos?" image={Inicio2} wrapImage={false}>
                        <p style={{fontSize:"0.9rem", lineHeight:"1.1rem"}}>
                        Nuestro modelo de negocio, centrado
                        en la creación de valor, está comprometido con el desarrollo sostenible de
                        la sociedad, garantizando el suministro
                        de energía competitiva, segura y con
                        el máximo respeto al medio ambiente.
                        </p>
                    </Tercia>

                    <Tercia title="¿Cómo lo hacemos?" image={Inicio3} wrapImage={false}>
                        <p style={{fontSize:"0.9rem", lineHeight:"1.1rem"}}>
                        Nuestro modelo de negocio, centrado
                        en la creación de valor, está comprometido con el desarrollo sostenible de
                        la sociedad, garantizando el suministro
                        de energía competitiva, segura y con
                        el máximo respeto al medio ambiente
                        </p>
                    </Tercia>

                </Row>
            </Container>

            <SeccionBlanca title="Nuestros Servicios" />

            <Container style={{marginTop:"-30px"}}>
                <Row className="justify-content-center">
                    <Col xs={12} md={2}>
                        <div className=" bg bg1">
                            <a href="servicios#gas_natural">
                                <img className="img-fluid" src={img1} alt="Servicios1" />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className=" bg bg2">
                            <a href="servicios#gas_natural_licuado">
                                <img className="img-fluid" src={img2} alt="Servicios1" />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className=" bg bg3">
                            <a href="servicios#gas_natural_renovable">
                                <img className="img-fluid" src={img3} alt="Servicios1" />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className=" bg bg4">
                            <a href="servicios#epc">
                                <img className="img-fluid" src={img4} alt="Servicios1" />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className=" bg bg5">
                            <a href="servicios#comercializacion">
                                <img className="img-fluid" src={img5} alt="Servicios1" />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NuestrosServicios;